import React from 'react';
import './TopQuotes.css';

function TopQuotes() {
  return (
    <div className="top-quotes-container" style={{ backgroundImage: 'url(/404.jpg)' }}>
    </div>
  );
}

export default TopQuotes;
