// personalFavoriteQuotes.js
const personalFavoriteQuotes = [
  "IT'S NOT A BUG, IT'S A FEATURE",
  "WE WILL ONLY REGRET THE CHANCES WE DON'T TAKE",
  "BELIEVE YOU CAN AND YOU ARE HALF WAY THERE",
  "IT'S YOU AGAINST YOU. ONLY YOU MAKE YOURSELF BETTER"
  // Add more personal favorite quotes
];

export default personalFavoriteQuotes;
